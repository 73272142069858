<template>
    <div v-if="modalOpen" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal rounded-3xl" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">

                    <div>
                        <div class="grid grid-cols-2 gap-4 place-content-end">
                            <div>
                                <span class="block font-bold text-gray-900 text-xl" id="modal-title">Edit custom tag</span> 
                            </div>
                            <div class="justify-self-end font-bold">
                                <component :is="icons.XIcon" @click="toggleModal()" class="w-6 h-6 cursor-pointer" />
                            </div>
                        </div>

                        <hr class="mt-8 h-px border-0 bg-gray-100">

                        <div class="mt-6 space-y-3">
                            <span class="block font-bold text-gray-900 text-md" id="modal-title">Tag name</span> 
                            <t-input ref="name" 
                                    v-model="form.name" 
                                    placeholder="Enter a name for your custom gig tag." 
                            />
                            <p v-show="!form.name" class="text-xs text-red-500">Please input a custom tag name.</p>
                        </div>

                        <div class="mt-6 space-y-3">
                            <span class="block font-bold text-gray-900 text-md" id="modal-title">Tag internal description</span> 
                            <textarea v-model="form.description" id="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" 
                                placeholder="Enter a description for your custom gig tag."
                            />
                        </div>

                        <span class="mt-6 block font-bold text-gray-900 text-md" id="modal-title">Icon</span> 
                        <div class="mt-2 flex items-center justify-between">
                            <div class="w-full">
                                <TRichSelect 
                                    v-show="!form.icon" 
                                    ref="iconselect"  
                                    class="col-span-12 lg:col-span-5"
                                    :close-on-select="true" valueAttribute="value"  textAttribute="text"
                                    :options="icons"
                                    v-model="form.icon"
                                    placeholder="Choose a tag icon" 
                                >  
                                    <template slot="option" slot-scope="{ index, isHighlighted, isSelected, className, option }">                                 
                                        <div class="flex gap-4 py-2">
                                            <component :is="icons[option.value]" class="ml-3"/>  {{ option.value }} 
                                        </div>
                                    </template>
                                </TRichSelect> 
                                <p v-show="!form.icon" class="text-xs text-red-500 mt-3">Please select a custom tag icon.</p>

                                <div v-show="form.icon" class="flex items-center space-x-3">
                                    <div :style="{ color: form.icon_colour }">
                                        <component :is="icons[form.icon]" class="w-6 h-6"/>
                                    </div>
                                    <span>{{ form.icon }}</span>

                                    <component @click="form.icon = '';" :is="icons['XCircleIcon']" class="ml-2 w-4 h-4 text-red-600 cursor-pointer"/>
                                </div>
                            </div>
                            <div class="w-full">
                                <div class="flex items-center justify-center">
                                    <input v-model="form.icon_colour" type="color" class="p-1 h-10 w-14 block bg-white  cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none" id="hs-color-input" value="#2563eb" title="Choose your color">
                                    <span class="text-xs ml-3 font-bold hidden sm:block">Select Colour</span>
                                </div>
                            </div>
                        </div>

                        <footer class="flex items-center justify-between mt-12">
                            <div>
                                <GPButton 
                                        @click.native="deleteCustomTag()" 
                                        :color="'pink'"
                                        :disabled="isUpdating"
                                        :loading="isUpdating"
                                        size="md"
                                        class="w-full"
                                    >Delete tag</GPButton>
                            </div>
                            <div>
                                <GPButton 
                                        @click.native="updateCustomTag()" 
                                        :color="'pink'"
                                        :disabled="isFormCompleted || isUpdating"
                                        :loading="isUpdating"
                                        size="md"
                                        class="w-full"
                                    >Save custom Tag</GPButton>
                            </div>
                        </footer>
                    </div>
                </div>
            </transition>        
        </div>
        <NotificationSmall ref="notification" />
    </div>
</template>

<script>
import { TInput, TRichSelect } from 'vue-tailwind/dist/components';
import GPButton from '../../../components/ui/buttons/Button.vue';
import  * as icons from "@vue-hero-icons/outline" 
import client from '@/utils/client.js'
import NotificationSmall from '../../../components/notifications/small.vue';

export default
{
    name: "EditCustomGigTag", 
    components:{
        TInput, TRichSelect, GPButton, NotificationSmall
    },
    data(){
        return {
            modalOpen: false,
            icons: icons,
            form: {
                id: null,
                name: '',
                description: '',
                icon: '',
                icon_colour: '#F17190',
                allow_brand_visibility: false
            },
            form_reset: {
                id: null,
                name: '',
                description: '',
                icon: '',
                icon_colour: '#F17190',
                allow_brand_visibility: false
            },
            isUpdating: false
        }
    },
    computed: {
        isFormCompleted() {
            return !this.form.name || !this.form.icon;
        }
    },
    methods: {
        toggleModal()
        {
            this.modalOpen = !this.modalOpen;
        },
        updateTag($tag)
        {
            this.form = JSON.parse(JSON.stringify($tag));
        },
        async deleteCustomTag()
        {
            this.isUpdating = true;

            await client.delete('custom-tags/' + this.form.id)
                .then(() => {
                    this.$emit('deletedTag', this.form);
                    this.$notify({text: 'Custom tag successfully deleted.',  duration: 5000});
                    Object.assign(this.form, this.form_reset);
                    this.toggleModal();
                })
                .catch((error) => {
                    this.$notify({text: error.response.data.message,  duration: 5000});
                })
                .finally(() => {
                    this.isUpdating = false;
                })
        },
        async updateCustomTag()
        {
            this.isUpdating = true;

            await client.put('custom-tags/' + this.form.id , this.form)
                .then((response) => {
                    this.$emit('updatedTag', response.data.data);
                    this.$notify({text: 'Custom tag successfully updated.',  duration: 5000});
                    Object.assign(this.form, this.form_reset);
                    this.toggleModal();
                })
                .catch((error) => {
                    this.$notify({text: error.response.data.message,  duration: 5000});
                })
                .finally(() => {
                    this.isUpdating = false;
                })
        }
    }
}

</script>